import { MdArrowBackIosNew } from 'react-icons/md';
import { BsFacebook, BsInstagram, BsTiktok, BsYoutube } from 'react-icons/bs';
import { MyLink } from './MyLink';
import { useState } from 'react';
import { Loading } from './Loading';
// import Trustpilot from './Trustpilot';
import { BsTwitterX } from 'react-icons/bs';
import { LogoFull } from './Icons';
import { useTranslation } from 'next-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CheckIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { RxCross2 } from 'react-icons/rx';
import { useRouter } from 'next/router';
import { classNames } from '../utils';

export function Footer() {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState('');
  const [loading, setLoading] = useState(false);

  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const { t } = useTranslation(['homepage', 'common']);

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const changeLanguage = (event) => {
    const locale = event;
    router.push({ pathname, query }, asPath, { locale: locale });
    setIsOpen(false);
  };

  function ResponsiveDrawer() {
    return (
      <>
        <button onClick={openModal} className="flex gap-2 rounded-md font-semibold text-gray-600">
          <GlobeAltIcon className="h-5 w-5" />
          {locale == 'de' ? 'Deutsch' : 'English'}
        </button>

        <Transition as={Fragment} show={isOpen}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center py-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95">
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="px-8 text-lg font-medium leading-6 text-gray-900">
                      {t('common:language')}
                    </Dialog.Title>
                    <button
                      onClick={closeModal}
                      className="absolute right-2 top-2 rounded-md px-2 py-1 text-gray-500 hover:text-gray-700 focus:outline-none">
                      <RxCross2 className="h-6 w-6" />
                    </button>

                    <div className="mt-4">
                      <ul>
                        <li
                          onClick={() => changeLanguage('de')}
                          className="relative cursor-pointer px-10 py-2 hover:bg-gray-100">
                          Deutsch
                          <span
                            className={classNames(
                              locale == 'de' ? 'text-black' : 'hidden text-black',
                              'absolute inset-y-0 right-0 flex items-center pr-10'
                            )}>
                            <CheckIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
                          </span>
                        </li>
                        <li
                          onClick={() => changeLanguage('en')}
                          className="relative cursor-pointer px-10 py-2 hover:bg-gray-100">
                          English
                          <span
                            className={classNames(
                              locale == 'en' ? 'text-black' : 'hidden text-black',
                              'absolute inset-y-0 right-0 flex items-center pr-10'
                            )}>
                            <CheckIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }
  const newslettersignup = async (event, email) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`/api/newsletterSignUp/`, {
        method: 'POST',
        body: JSON.stringify(email),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok || response.status === 202) {
        setSent('Hinzugefügt!');
      } else if (response.status === 400) {
        setSent('Email nicht valide!');
      } else if (response.status === 404) {
        setSent('Email nicht hinzugefügt!');
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSent('Email nicht hinzugefügt!');
      console.error(error);
    }
  };

  return (
    <footer className="relative z-[9] mx-auto max-w-[90vw] pt-10 max-md:pb-16">
      {/* backtotop mobile*/}
      {/* z-index needed for button being clickable on mobile */}
      <div className="absolute right-4 top-20 z-[1] sm:right-6 sm:top-6 lg:right-8 lg:top-8 lg:hidden">
        <a
          className="inline-block rounded-full bg-white p-2 text-primary-color shadow transition-all duration-300 hover:scale-110 sm:p-3 lg:p-4"
          href="#top"
          aria-label="Scroll wieder ganz nach oben">
          <MdArrowBackIosNew style={{ transform: 'rotate(90deg)' }} className="text-2xl" />
        </a>
      </div>
      <div className="flex flex-col-reverse gap-10 lg:flex-row lg:justify-around">
        <div
          style={{ gridTemplateRows: 'auto auto' }}
          className="grid-ro relative grid grow grid-cols-2 gap-8 max-sm:mt-10 sm:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4">
          {/* Trust and legal */}
          <div>
            <h3 className="mb-3 w-max text-xl font-bold text-[color:var(--primary-text)]">{t('common:footer1')}</h3>
            <ul>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://blog.esfaras.de'}>
                  Blog
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'/ueber-uns'}>
                  {t('common:footer11')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'/fuer-kunden'}>
                  {t('common:footer2')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'/fuer-anbieter'}>
                  {t('common:footer3')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'/empfehlungsprogramm'}>
                  {t('common:footer4')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'/anbieter-mitgliedschaft'}>
                  {t('common:footer5')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'/pressebereich'}>
                  {t('common:footer6')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://hilfe.esfaras.de/'}>
                  {t('common:helpcenter')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'/kontaktiere-uns'}>
                  {t('common:footer7')}
                </MyLink>
              </li>
            </ul>
          </div>
          {/* Services */}
          <div>
            <h3 className="mb-3 w-max text-xl font-bold text-[color:var(--primary-text)]">
              {t('common:local_services')}
            </h3>
            <ul>
              <li className="mb-2 cursor-pointer text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Aufnehmen',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('recording')}
                </MyLink>
              </li>
              <li className="mb-2 cursor-pointer text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Mischen',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('mixing')}
                </MyLink>
              </li>
              <li className="mb-2 cursor-pointer text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Mastern',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('mastering')}
                </MyLink>
              </li>
              <li className="mb-2 cursor-pointer text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Musikproduktion',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('musicproduction')}
                </MyLink>
              </li>
              <li className="mb-2 cursor-pointer whitespace-nowrap text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Podcasts & Hörbücher',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('podcasts_and_audiobooks')}
                </MyLink>
              </li>
              <li className="mb-2 cursor-pointer text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Studio mieten',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('rent_studio')}
                </MyLink>
              </li>
              <li className="mb-2 cursor-pointer text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Proberaum mieten',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('rent_rehearsal_room')}
                </MyLink>
              </li>
              <li className="mb-2 cursor-pointer whitespace-nowrap text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Musikunterricht',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('music_lessons')}
                </MyLink>
              </li>
              <li className="mb-2 cursor-pointer whitespace-nowrap text-base text-gray-600">
                <MyLink
                  rel="nofollow"
                  prefetch={false}
                  href={{
                    pathname: '/lokale-leistungen',
                    query: {
                      filters: JSON.stringify({
                        services: 'Sonstige',
                      }),
                      page: 1,
                    },
                  }}>
                  {t('other')}
                </MyLink>
              </li>
            </ul>
          </div>

          {/* Online Leistungen */}
          <div>
            <h3 className="mb-3 w-max text-xl font-bold text-[color:var(--primary-text)]">
              {t('common:online_services')}
            </h3>
            <ul>
              <li className="mb-2 text-base text-gray-600">
                <MyLink
                  prefetch={false}
                  href={'https://www.esfaras.de/online-leistungen/Produzenten%20&%20Komponisten'}>
                  {t('producers_and_composers')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://www.esfaras.de/online-leistungen/Songwriter'}>
                  Songwriter
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://www.esfaras.de/online-leistungen/Voice%20Over'}>
                  Voice Over
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://www.esfaras.de/online-leistungen/Beats%20produzieren'}>
                  {t('beats_production')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://www.esfaras.de/online-leistungen/Sänger%20&%20Vokalisten'}>
                  {t('singers_and_vocalists')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://www.esfaras.de/online-leistungen/Studiomusiker'}>
                  {t('session_musicians')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink
                  prefetch={false}
                  href={'https://www.esfaras.de/online-leistungen/Erkennungsmelodien%20&%20Intros'}>
                  {t('jingles_and_intros')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://www.esfaras.de/online-leistungen/Mischen%20&%20Mastern'}>
                  {t('mixing_and_mastering')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://www.esfaras.de/online-leistungen/Audiobearbeitung'}>
                  {t('audio_editing')}
                </MyLink>
              </li>
              <li className="mb-2 text-base text-gray-600">
                <MyLink prefetch={false} href={'https://www.esfaras.de/online-leistungen/Beats%20produzieren'}>
                  {t('beats_production')}
                </MyLink>
              </li>
            </ul>
          </div>
        </div>
        {/* Logo, socials, Newsletter */}
        <div className="relative w-full lg:max-w-[40%] lg:pl-10">
          {/* backtotop */}
          <div className="absolute right-4 top-4 z-[1] hidden sm:right-6 sm:top-6 lg:right-8 lg:top-8 lg:block">
            <a
              className="inline-block rounded-full bg-white p-2 text-primary-color shadow transition-all duration-300 hover:scale-110 sm:p-3 lg:p-4"
              href="#top">
              <MdArrowBackIosNew style={{ transform: 'rotate(90deg)' }} className="text-2xl" />
            </a>
          </div>
          <LogoFull width={500} height={100} className="w-full fill-black" />
          {/* newsletter */}
          <div className="mt-5">
            <p className="mb-2 text-lg font-semibold text-indigo-500">{t('common:footer8')}</p>
            <div className="relative flex w-full items-center">
              <form
                onSubmit={(event) => newslettersignup(event, email)}
                className="flex w-full max-w-[80%] max-sm:max-w-full">
                <input
                  type="email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="E-Mail-Adresse"
                  required
                  className="w-full rounded-l-xl rounded-r-none border-none bg-gray-200 py-3 pl-5 pr-5"
                />
                <button
                  type="submit"
                  className="relative right-[2.5%] w-[22.5%] shrink-0 rounded-lg rounded-l-none bg-indigo-500 px-1 py-3 text-white max-sm:w-[25.5%] max-sm:px-2">
                  {t('common:footer9')}
                </button>
              </form>
              {sent ? (
                <span className={`${sent === 'Hinzugefügt!' ? 'text-green-500 ' : 'text-red-500'} flex items-center`}>
                  {sent}
                </span>
              ) : loading ? (
                <Loading style="!h-6 !w-6 shrink-0" />
              ) : null}
            </div>
            <div className="my-2 flex items-center gap-2">
              {/* insta */}
              <div className="w-min rounded-full bg-white p-2">
                <MyLink
                  prefetch={false}
                  rel="noopener nofollow"
                  target="_blank"
                  href="https://www.instagram.com/esfaras.de/">
                  <BsInstagram className="h-6 w-6 text-2xl text-indigo-500" />
                  <span className="sr-only">Instagram</span>
                </MyLink>
              </div>
              <div className="w-min rounded-full bg-white p-2">
                <MyLink
                  rel="noopener nofollow"
                  target="_blank"
                  prefetch={false}
                  href="https://www.tiktok.com/@oziesfaras?_t=8mCrleSd9kH&_r=1">
                  <BsTiktok className="h-6 w-6 text-2xl text-indigo-500" />
                  <span className="sr-only">TikTok</span>
                </MyLink>
              </div>
              <div className="w-min rounded-full bg-white p-2">
                <MyLink
                  rel="noopener nofollow"
                  target="_blank"
                  prefetch={false}
                  href="https://www.youtube.com/channel/UCkufLg4U4RxfLVQ27s5B4bg">
                  <BsYoutube className="h-6 w-6 text-2xl text-indigo-500" />
                  <span className="sr-only">Youtube</span>
                </MyLink>
              </div>
              <div className="w-min rounded-full bg-white p-2">
                <MyLink rel="noopener nofollow" target="_blank" prefetch={false} href="https://twitter.com/esfaras">
                  <BsTwitterX className="h-6 w-6 text-2xl text-indigo-500" />
                  <span className="sr-only">Twitter</span>
                </MyLink>
              </div>
              <div className="w-min rounded-full bg-white p-2">
                <MyLink
                  rel="noopener nofollow"
                  target="_blank"
                  prefetch={false}
                  href="https://www.facebook.com/esfaras.de/">
                  <BsFacebook className="h-6 w-6 text-2xl text-indigo-500" />
                  <span className="sr-only">Facebook</span>
                </MyLink>
              </div>
            </div>
            <div className="flex flex-col items-end justify-center max-lg:w-full sm:max-xl:items-start lg:mt-4 lg:max-w-[85%]">
              <div className="flex items-center gap-1 text-xs uppercase">
                <svg width="9" height="11" viewBox="0 0 9 11" xmlns="http://www.w3.org/2000/svg" data-v-5729cff9="">
                  <path
                    fillRule="evenodd"
                    d="M7.714 4.278V3.056C7.714 1.369 6.274 0 4.5 0S1.286 1.369 1.286 3.056v1.222C.579 4.278 0 4.828 0 5.5v4.278C0 10.45.579 11 1.286 11h6.428C8.421 11 9 10.45 9 9.778V5.5c0-.672-.579-1.222-1.286-1.222zM3 4.078V3.056C3 2.383 3.5 1.5 4.5 1.5S6 2.383 6 3.056v1.022a.2.2 0 01-.2.2H3.2a.2.2 0 01-.2-.2zM5.1 9.3v-.832c0-.08.048-.15.117-.188C5.68 8.028 6 7.543 6 6.982 6 6.167 5.325 5.5 4.5 5.5S3 6.167 3 6.982c0 .56.32 1.046.783 1.298.07.038.117.108.117.188V9.3c0 .11.09.2.2.2h.8a.2.2 0 00.2-.2z"></path>
                </svg>
                {t('common:footer10')}
              </div>
              <div
                className="items-end sm:max-xl:items-start"
                style={{
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                  boxSizing: 'border-box',
                }}>
                <div style={{ marginTop: '15px', boxSizing: 'border-box' }}>
                  <span
                    style={{
                      overflow: 'hidden',
                      width: '1px',
                      height: '1px',
                      margin: '-1px',
                      padding: '0px',
                      border: '0px none rgba(18, 18, 18, 0.75)',
                      clip: 'rect(0px, 0px, 0px, 0px)',
                      position: 'absolute',
                      overflowWrap: 'normal',
                      boxSizing: 'border-box',
                    }}>
                    Zahlungsmethoden
                  </span>
                  <ul
                    role="list"
                    style={{
                      justifyContent: 'flex-end',
                      margin: '-5px',
                      paddingTop: '0px',
                      display: 'flex',
                      flexWrap: 'wrap',
                      paddingLeft: '0px',
                      boxSizing: 'border-box',
                    }}>
                    {/* apple */}
                    <li style={{ alignItems: 'center', display: 'flex', padding: '5px', boxSizing: 'border-box' }}>
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        x="0"
                        y="0"
                        width="38"
                        height="24"
                        viewBox="0 0 165.521 105.965"
                        space="preserve"
                        aria-labelledby="pi-apple_pay"
                        style={{ boxSizing: 'border-box' }}>
                        <title style={{ boxSizing: 'border-box' }}>Apple Pay</title>
                        <path
                          fill="#000"
                          opacity=".07"
                          d="M150.698 0H14.823c-.566 0-1.133 0-1.698.003-.477.004-.953.009-1.43.022-1.039.028-2.087.09-3.113.274a10.51 10.51 0 0 0-2.958.975 9.932 9.932 0 0 0-4.35 4.35 10.463 10.463 0 0 0-.975 2.96C.113 9.611.052 10.658.024 11.696a70.22 70.22 0 0 0-.022 1.43C0 13.69 0 14.256 0 14.823v76.318c0 .567 0 1.132.002 1.699.003.476.009.953.022 1.43.028 1.036.09 2.084.275 3.11a10.46 10.46 0 0 0 .974 2.96 9.897 9.897 0 0 0 1.83 2.52 9.874 9.874 0 0 0 2.52 1.83c.947.483 1.917.79 2.96.977 1.025.183 2.073.245 3.112.273.477.011.953.017 1.43.02.565.004 1.132.004 1.698.004h135.875c.565 0 1.132 0 1.697-.004.476-.002.952-.009 1.431-.02 1.037-.028 2.085-.09 3.113-.273a10.478 10.478 0 0 0 2.958-.977 9.955 9.955 0 0 0 4.35-4.35c.483-.947.789-1.917.974-2.96.186-1.026.246-2.074.274-3.11.013-.477.02-.954.022-1.43.004-.567.004-1.132.004-1.699V14.824c0-.567 0-1.133-.004-1.699a63.067 63.067 0 0 0-.022-1.429c-.028-1.038-.088-2.085-.274-3.112a10.4 10.4 0 0 0-.974-2.96 9.94 9.94 0 0 0-4.35-4.35A10.52 10.52 0 0 0 156.939.3c-1.028-.185-2.076-.246-3.113-.274a71.417 71.417 0 0 0-1.431-.022C151.83 0 151.263 0 150.698 0z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#FFF"
                          d="M150.698 3.532l1.672.003c.452.003.905.008 1.36.02.793.022 1.719.065 2.583.22.75.135 1.38.34 1.984.648a6.392 6.392 0 0 1 2.804 2.807c.306.6.51 1.226.645 1.983.154.854.197 1.783.218 2.58.013.45.019.9.02 1.36.005.557.005 1.113.005 1.671v76.318c0 .558 0 1.114-.004 1.682-.002.45-.008.9-.02 1.35-.022.796-.065 1.725-.221 2.589a6.855 6.855 0 0 1-.645 1.975 6.397 6.397 0 0 1-2.808 2.807c-.6.306-1.228.511-1.971.645-.881.157-1.847.2-2.574.22-.457.01-.912.017-1.379.019-.555.004-1.113.004-1.669.004H14.801c-.55 0-1.1 0-1.66-.004a74.993 74.993 0 0 1-1.35-.018c-.744-.02-1.71-.064-2.584-.22a6.938 6.938 0 0 1-1.986-.65 6.337 6.337 0 0 1-1.622-1.18 6.355 6.355 0 0 1-1.178-1.623 6.935 6.935 0 0 1-.646-1.985c-.156-.863-.2-1.788-.22-2.578a66.088 66.088 0 0 1-.02-1.355l-.003-1.327V14.474l.002-1.325a66.7 66.7 0 0 1 .02-1.357c.022-.792.065-1.717.222-2.587a6.924 6.924 0 0 1 .646-1.981c.304-.598.7-1.144 1.18-1.623a6.386 6.386 0 0 1 1.624-1.18 6.96 6.96 0 0 1 1.98-.646c.865-.155 1.792-.198 2.586-.22.452-.012.905-.017 1.354-.02l1.677-.003h135.875"
                          style={{ boxSizing: 'border-box' }}></path>
                        <g style={{ boxSizing: 'border-box' }}>
                          <g style={{ boxSizing: 'border-box' }}>
                            <path
                              fill="#000"
                              d="M43.508 35.77c1.404-1.755 2.356-4.112 2.105-6.52-2.054.102-4.56 1.355-6.012 3.112-1.303 1.504-2.456 3.959-2.156 6.266 2.306.2 4.61-1.152 6.063-2.858"
                              style={{ boxSizing: 'border-box' }}></path>
                            <path
                              fill="#000"
                              d="M45.587 39.079c-3.35-.2-6.196 1.9-7.795 1.9-1.6 0-4.049-1.8-6.698-1.751-3.447.05-6.645 2-8.395 5.1-3.598 6.2-.95 15.4 2.55 20.45 1.699 2.5 3.747 5.25 6.445 5.151 2.55-.1 3.549-1.65 6.647-1.65 3.097 0 3.997 1.65 6.696 1.6 2.798-.05 4.548-2.5 6.247-5 1.95-2.85 2.747-5.6 2.797-5.75-.05-.05-5.396-2.101-5.446-8.251-.05-5.15 4.198-7.6 4.398-7.751-2.399-3.548-6.147-3.948-7.447-4.048"
                              style={{ boxSizing: 'border-box' }}></path>
                          </g>
                          <g style={{ boxSizing: 'border-box' }}>
                            <path
                              fill="#000"
                              d="M78.973 32.11c7.278 0 12.347 5.017 12.347 12.321 0 7.33-5.173 12.373-12.529 12.373h-8.058V69.62h-5.822V32.11h14.062zm-8.24 19.807h6.68c5.07 0 7.954-2.729 7.954-7.46 0-4.73-2.885-7.434-7.928-7.434h-6.706v14.894z"
                              style={{ boxSizing: 'border-box' }}></path>
                            <path
                              fill="#000"
                              d="M92.764 61.847c0-4.809 3.665-7.564 10.423-7.98l7.252-.442v-2.08c0-3.04-2.001-4.704-5.562-4.704-2.938 0-5.07 1.507-5.51 3.82h-5.252c.157-4.86 4.731-8.395 10.918-8.395 6.654 0 10.995 3.483 10.995 8.89v18.663h-5.38v-4.497h-.13c-1.534 2.937-4.914 4.782-8.579 4.782-5.406 0-9.175-3.222-9.175-8.057zm17.675-2.417v-2.106l-6.472.416c-3.64.234-5.536 1.585-5.536 3.95 0 2.288 1.975 3.77 5.068 3.77 3.95 0 6.94-2.522 6.94-6.03z"
                              style={{ boxSizing: 'border-box' }}></path>
                            <path
                              fill="#000"
                              d="M120.975 79.652v-4.496c.364.051 1.247.103 1.715.103 2.573 0 4.029-1.09 4.913-3.899l.52-1.663-9.852-27.293h6.082l6.863 22.146h.13l6.862-22.146h5.927l-10.216 28.67c-2.34 6.577-5.017 8.735-10.683 8.735-.442 0-1.872-.052-2.261-.157z"
                              style={{ boxSizing: 'border-box' }}></path>
                          </g>
                        </g>
                      </svg>
                    </li>
                    {/* google */}
                    <li style={{ alignItems: 'center', display: 'flex', padding: '5px', boxSizing: 'border-box' }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        viewBox="0 0 38 24"
                        width="38"
                        height="24"
                        aria-labelledby="pi-google_pay"
                        style={{ boxSizing: 'border-box' }}>
                        <title style={{ boxSizing: 'border-box' }}>Google Pay</title>
                        <path
                          d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                          fill="#000"
                          opacity=".07"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                          fill="#FFF"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          d="M18.093 11.976v3.2h-1.018v-7.9h2.691a2.447 2.447 0 0 1 1.747.692 2.28 2.28 0 0 1 .11 3.224l-.11.116c-.47.447-1.098.69-1.747.674l-1.673-.006zm0-3.732v2.788h1.698c.377.012.741-.135 1.005-.404a1.391 1.391 0 0 0-1.005-2.354l-1.698-.03zm6.484 1.348c.65-.03 1.286.188 1.778.613.445.43.682 1.03.65 1.649v3.334h-.969v-.766h-.049a1.93 1.93 0 0 1-1.673.931 2.17 2.17 0 0 1-1.496-.533 1.667 1.667 0 0 1-.613-1.324 1.606 1.606 0 0 1 .613-1.336 2.746 2.746 0 0 1 1.698-.515c.517-.02 1.03.093 1.49.331v-.208a1.134 1.134 0 0 0-.417-.901 1.416 1.416 0 0 0-.98-.368 1.545 1.545 0 0 0-1.319.717l-.895-.564a2.488 2.488 0 0 1 2.182-1.06zM23.29 13.52a.79.79 0 0 0 .337.662c.223.176.5.269.785.263.429-.001.84-.17 1.146-.472.305-.286.478-.685.478-1.103a2.047 2.047 0 0 0-1.324-.374 1.716 1.716 0 0 0-1.03.294.883.883 0 0 0-.392.73zm9.286-3.75l-3.39 7.79h-1.048l1.281-2.728-2.224-5.062h1.103l1.612 3.885 1.569-3.885h1.097z"
                          fill="#5F6368"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          d="M13.986 11.284c0-.308-.024-.616-.073-.92h-4.29v1.747h2.451a2.096 2.096 0 0 1-.9 1.373v1.134h1.464a4.433 4.433 0 0 0 1.348-3.334z"
                          fill="#4285F4"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          d="M9.629 15.721a4.352 4.352 0 0 0 3.01-1.097l-1.466-1.14a2.752 2.752 0 0 1-4.094-1.44H5.577v1.17a4.53 4.53 0 0 0 4.052 2.507z"
                          fill="#34A853"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          d="M7.079 12.05a2.709 2.709 0 0 1 0-1.735v-1.17H5.577a4.505 4.505 0 0 0 0 4.075l1.502-1.17z"
                          fill="#FBBC04"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          d="M9.629 8.44a2.452 2.452 0 0 1 1.74.68l1.3-1.293a4.37 4.37 0 0 0-3.065-1.183 4.53 4.53 0 0 0-4.027 2.5l1.502 1.171a2.715 2.715 0 0 1 2.55-1.875z"
                          fill="#EA4335"
                          style={{ boxSizing: 'border-box' }}></path>
                      </svg>
                    </li>
                    {/* paypal */}
                    <li style={{ alignItems: 'center', display: 'flex', padding: '5px', boxSizing: 'border-box' }}>
                      <svg
                        viewBox="0 0 38 24"
                        xmlns="http://www.w3.org/2000/svg"
                        width="38"
                        height="24"
                        role="img"
                        aria-labelledby="pi-paypal"
                        style={{ boxSizing: 'border-box' }}>
                        <title style={{ boxSizing: 'border-box' }}>PayPal</title>
                        <path
                          opacity=".07"
                          d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#fff"
                          d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#003087"
                          d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#3086C8"
                          d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#012169"
                          d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z"
                          style={{ boxSizing: 'border-box' }}></path>
                      </svg>
                    </li>
                    {/* mastercard */}
                    <li style={{ alignItems: 'center', display: 'flex', padding: '5px', boxSizing: 'border-box' }}>
                      <svg
                        viewBox="0 0 38 24"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        width="38"
                        height="24"
                        aria-labelledby="pi-master"
                        style={{ boxSizing: 'border-box' }}>
                        <title style={{ boxSizing: 'border-box' }}>Mastercard</title>
                        <path
                          opacity=".07"
                          d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#fff"
                          d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                          style={{ boxSizing: 'border-box' }}></path>
                        <circle fill="#EB001B" cx="15" cy="12" r="7" style={{ boxSizing: 'border-box' }}></circle>
                        <circle fill="#F79E1B" cx="23" cy="12" r="7" style={{ boxSizing: 'border-box' }}></circle>
                        <path
                          fill="#FF5F00"
                          d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
                          style={{ boxSizing: 'border-box' }}></path>
                      </svg>
                    </li>
                    {/* visa */}
                    <li style={{ alignItems: 'center', display: 'flex', padding: '5px', boxSizing: 'border-box' }}>
                      <svg
                        viewBox="0 0 38 24"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        width="38"
                        height="24"
                        aria-labelledby="pi-visa"
                        style={{ boxSizing: 'border-box' }}>
                        <title style={{ boxSizing: 'border-box' }}>Visa</title>
                        <path
                          opacity=".07"
                          d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#fff"
                          d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"
                          fill="#142688"
                          style={{ boxSizing: 'border-box' }}></path>
                      </svg>
                    </li>
                    {/* express */}
                    <li style={{ alignItems: 'center', display: 'flex', padding: '5px', boxSizing: 'border-box' }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                        aria-labelledby="pi-american_express"
                        viewBox="0 0 38 24"
                        width="38"
                        height="24"
                        style={{ boxSizing: 'border-box' }}>
                        <title style={{ boxSizing: 'border-box' }}>American Express</title>
                        <path
                          fill="#000"
                          d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3Z"
                          opacity=".07"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#006FCF"
                          d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32Z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#FFF"
                          d="M22.012 19.936v-8.421L37 11.528v2.326l-1.732 1.852L37 17.573v2.375h-2.766l-1.47-1.622-1.46 1.628-9.292-.02Z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#006FCF"
                          d="M23.013 19.012v-6.57h5.572v1.513h-3.768v1.028h3.678v1.488h-3.678v1.01h3.768v1.531h-5.572Z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#006FCF"
                          d="m28.557 19.012 3.083-3.289-3.083-3.282h2.386l1.884 2.083 1.89-2.082H37v.051l-3.017 3.23L37 18.92v.093h-2.307l-1.917-2.103-1.898 2.104h-2.321Z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#FFF"
                          d="M22.71 4.04h3.614l1.269 2.881V4.04h4.46l.77 2.159.771-2.159H37v8.421H19l3.71-8.421Z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#006FCF"
                          d="m23.395 4.955-2.916 6.566h2l.55-1.315h2.98l.55 1.315h2.05l-2.904-6.566h-2.31Zm.25 3.777.875-2.09.873 2.09h-1.748Z"
                          style={{ boxSizing: 'border-box' }}></path>
                        <path
                          fill="#006FCF"
                          d="M28.581 11.52V4.953l2.811.01L32.84 9l1.456-4.046H37v6.565l-1.74.016v-4.51l-1.644 4.494h-1.59L30.35 7.01v4.51h-1.768Z"
                          style={{ boxSizing: 'border-box' }}></path>
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <Trustpilot /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 w-full border-b text-gray-600" />
      {/* bottom */}
      <div className="flex gap-2 py-6 text-sm text-gray-600 max-md:flex-col">
        <ul className="flex flex-wrap gap-2">
          <li className="">
            <span className="whitespace-nowrap">
              @ {new Date().getFullYear()} esfaras. <span className="md:hidden">Alle Rechte vorbehalten</span>
            </span>{' '}
          </li>
          <li className="max-md:hidden">
            <span>·</span>
          </li>
          <li>
            <MyLink prefetch={false} href="/datenschutz">
              {t('common:footer12')}
            </MyLink>
          </li>
          <li>
            <span>·</span>
          </li>
          <li>
            <MyLink prefetch={false} href="/allgemeine-geschaeftsbedingungen">
              {t('common:footer13')}
            </MyLink>
          </li>
          <li>
            <span>·</span>
          </li>
          <li>
            <MyLink prefetch={false} href="/sitemaps">
              Sitemap
            </MyLink>
          </li>
          <li>
            <span>·</span>
          </li>
          <li>
            <MyLink prefetch={false} href={'/impressum'}>
              {t('common:footer14')}
            </MyLink>
          </li>
          <li className="max-md:hidden">
            <span>·</span>
          </li>
          <li className="max-md:hidden">
            <a href="mailto:support@esfaras.de">support@esfaras.de</a>
          </li>
          <li className=" max-lg:hidden max-sm:flex">
            <span>·</span>
          </li>
          <ResponsiveDrawer />
        </ul>
      </div>
    </footer>
  );
}
