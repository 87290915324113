import { HeartIcon, HomeIcon, UserCircleIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import React, { useRef } from 'react';
import MenuBar from './Header/MenuBar';
import { useRouter } from 'next/router';
import { MyLink } from './MyLink';
import { useSession } from 'next-auth/react';
import { useDispatch } from 'react-redux';
import { updateModalstate } from '../slices/activateModal';
import { Icon1, Icon2, Icon3, Icon4, Icon6, Icon7, Icon8 } from './Icons';

//bottom mobile nav comp

const NAV = [
  {
    name: 'Startseite',
    link: '/',
    icon: Icon1,
  },
  {
    name: 'Lokal',
    link: '/lokale-leistungen?filters=%7B"location"%3A""%7D&page=1',
    icon: Icon3,
  },
  {
    name: 'Online',
    link: '/online-leistungen?filters=%7B"search"%3A""%2C"priceMin"%3A5%2C"priceMax"%3A9999%2C"sortBy"%3A"Empfohlen"%7D&page=1',
    icon: Icon2,
  },
  {
    name: 'Tonstudios',
    link: '/tonstudios?filters=%7B"studioType"%3A""%2C"location"%3A""%7D&page=1',
    icon: Icon4,
  },
  {
    name: 'Menu',
    icon: MenuBar,
  },
];
const NAVsignedIn = [
  {
    name: 'Start',
    link: '/',
    icon: Icon1,
  },
  {
    name: 'Merkliste',
    link: '/dashboard/merkliste',
    icon: Icon6,
  },
  {
    name: 'Nachrichten',
    link: '/dashboard/nachrichten',
    icon: Icon7,
  },
  {
    name: 'Dashboard',
    link: '/dashboard',
    icon: Icon8,
  },
  {
    name: 'Menu',
    icon: MenuBar,
  },
];
//

function FooterNavStatic({ chat }) {
  const dispatch = useDispatch();
  const { data: session, status } = useSession();
  const userID = session?.token?.id;

  const containerRef = useRef(null);
  const router = useRouter();
  const pathname = router.asPath;

  const renderSignedInItem = (item, index) => {
    let isActive;
    if (pathname.includes('online-leistungen') && item.link?.includes('online-leistungen')) {
      isActive = true;
    } else if (userID && pathname.includes('bookmarks') && item.link?.includes('bookmarks')) {
      isActive = true;
    } else if (
      userID &&
      pathname.includes('dashboard') &&
      item.link?.includes('dashboard') &&
      !item.link?.includes('nachrichten')
    ) {
      isActive = true;
    } else if (
      userID &&
      !pathname.includes('dashboard') &&
      !item.link?.includes('dashboard') &&
      item.link?.includes('nachrichten')
    ) {
      isActive = true;
    } else {
      isActive = pathname === item.link;
    }

    return item.link ? (
      item.name == 'Nachrichten' ? (
        <MyLink
          key={index}
          prefetch={false}
          href={item.link}
          aria-label={item.link}
          className={`group flex rounded-xl px-2 py-2 transition duration-200 xl:px-2.5 xl:py-2.5`}>
          <item.icon
            className={`h-8 w-8 fill-black transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
          />
        </MyLink>
      ) : item.link === 'Log in' || item.link === 'Registrieren' ? (
        <button
          key={index}
          onClick={() => {
            item.link === 'Log in'
              ? dispatch(updateModalstate({ signin: true }))
              : item.link === 'Registrieren'
              ? dispatch(updateModalstate({ signup: true }))
              : null;
          }}
          aria-label={item.link}
          className={`group flex rounded-xl bg-indigo-700 px-2 py-2 transition duration-200 xl:px-2.5 xl:py-2.5`}>
          <item.icon
            className={`h-6 w-6 text-white transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
          />
        </button>
      ) : (
        <MyLink
          prefetch={false}
          key={index}
          href={item.link}
          aria-label={item.link}
          className={`group flex rounded-xl px-2 py-2 transition duration-200 xl:px-2.5 xl:py-2.5`}>
          <item.icon
            className={`h-8 w-8 fill-black transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
          />
        </MyLink>
      )
    ) : (
      <div aria-label={'Nutzermenü'} key={index}>
        <item.icon />
      </div>
    );
  };
  const renderItem = (item, index) => {
    let isActive;
    if (pathname.includes('online-leistungen') && item.link?.includes('online-leistungen')) {
      isActive = true;
    } else {
      isActive = pathname === item.link;
    }

    return item.link ? (
      item.link === 'Log in' || item.link === 'Registrieren' ? (
        <button
          key={index}
          aria-label={item.link}
          onClick={() => {
            item.link === 'Log in'
              ? dispatch(updateModalstate({ signin: true }))
              : item.link === 'Registrieren'
              ? dispatch(updateModalstate({ signup: true }))
              : null;
          }}
          className={`group flex rounded-xl px-2 py-2 transition duration-200 xl:px-2.5 xl:py-2.5`}>
          <item.icon
            className={`h-6 w-6 text-white transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
          />
        </button>
      ) : (
        <MyLink
          aria-label={item.link}
          prefetch={false}
          key={index}
          href={item.link}
          className={`group flex rounded-xl px-2 py-2 transition duration-200 xl:px-2.5 xl:py-2.5`}>
          <item.icon
            className={`h-8 w-8 fill-black transition duration-300 group-hover:scale-125 group-hover:drop-shadow-sm`}
          />
        </MyLink>
      )
    ) : (
      <div aria-label={'Nutzermenü'} key={index}>
        <item.icon />
      </div>
    );
  };

  return (
    // from z-30 to z-20 because bookingrequest modal is showing footernav
    <div
      ref={containerRef}
      className={`${
        chat ? '  ' : 'fixed bottom-0 top-auto '
      } FooterNav w-xl inset-x-0 z-20 block border-t border-neutral-300 bg-white p-2 py-3 transition-transform duration-300 ease-in-out md:!hidden`}>
      <div className="w-xl mx-auto flex max-w-lg justify-around text-center text-sm ">
        {/* MENU */}
        {userID ? NAVsignedIn.map(renderSignedInItem) : NAV.map(renderItem)}
      </div>
    </div>
  );
}

export default FooterNavStatic;
